.poka_pattern_dark {
  background-image: radial-gradient(
      rgba(229, 173, 82, 0.4) 0.7px,
      transparent 0.7px
    ),
    radial-gradient(rgba(229, 173, 82, 0.4) 0.7px, transparent 0.7px);
  background-size: 22px 22px;
  background-position:
    0 0,
    11px 11px;
}
