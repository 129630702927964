@font-face {
  font-family: Gilroy;
  src: url("../fonts/Gilroy-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Gilroy;
  src: url("../fonts/Gilroy-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Gilroy;
  src: url("../fonts/Gilroy-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Gilroy;
  src: url("../fonts/Gilroy-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Gilroy;
  src: url("../fonts/Gilroy-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Gilroy;
  src: url("../fonts/Gilroy-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Gilroy;
  src: url("../fonts/Gilroy-Thin.ttf");
  font-weight: 200;
}
