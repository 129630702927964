@media print {
  * {
    padding: 0;
    margin: 0;
  }

  html,
  body {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    font-family: Gilroy;
  }

  @page {
    padding: 0.5rem !important;
  }

  .print-root {
    width: 100%;
    display: block !important;
  }

  .print-header {
    display: flex;
    flex-direction: column;
  }

  .print-full-length {
    max-width: none !important;
    overflow: hidden !important;
    display: block !important;
    white-space: normal !important;
    text-overflow: none !important;
  }

  @font-face {
    font-family: "MerchantCopyDoublesize";
    src: url("../fonts/Merchant-Copy-Doublesize.ttf");
    font-weight: normal;
    font-style: normal;
  }

  .print-receipt-footer h6 {
    font-size: 14px;
  }

  .print-receipt-header h6 {
    font-size: 14px;
  }

  .print-receipt {
    height: 100%;
    font-family: "MerchantCopyDoublesize", monospace;
    font-size: 14px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .account-statement {
    margin-bottom: 2rem;
  }

  .account-statement .d-flex {
    display: flex;
  }

  .account-statement .busi-part {
    width: 100%;
    align-items: center;
  }

  .account-statement .busi-part .report-title {
    margin-left: auto;
    align-self: flex-end;
    text-transform: uppercase;
    justify-self: end;
  }

  .account-statement .busi-part .business-logo,
  .report .business-logo {
    height: 80px;
    width: 100px;
  }

  .account-statement .busi-part .business-logo img,
  .report .business-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .account-statement .busi-part .business-details {
    margin-left: 15px;
  }

  .business-details h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }

  .business-details p {
    line-height: 1.1;
    font-size: 1rem;
    font-weight: 500;
  }

  .account-statement .statement-title {
    align-items: center;
    margin-top: 2rem;
    justify-content: space-between;
  }

  .statement-title .client-details p {
    line-height: 1.1;
    font-size: 1rem;
    font-weight: 500;
  }

  .account-statement .statement-details table td {
    font-weight: 500;
    padding: 5px;
  }

  .d-print-none {
    display: none !important;
  }

  .print-max-height {
    max-height: 100%;
  }

  .MuiTableContainer-root {
    overflow: visible !important;
    overflow-x: hidden !important;
  }

  /* td.MuiTableCell-root.MuiTableCell-body {
    border-bottom: 1px solid #1F5A2D !important;
    border-left: 1px solid #1F5A2D !important;
  }

  th.MuiTableCell-root.MuiTableCell-head{
    border-bottom: 1px solid #1F5A2D !important;
    border-top: 1px solid #1F5A2D !important;
    border-left: 1px solid #1F5A2D !important;
  }

  tr.MuiTableRow-root.MuiTableRow-head th:last-child {
    border-right: 1px solid #1F5A2D !important;
  }

  tr.MuiTableRow-root td:last-child {
    border-right: 1px solid #1F5A2D !important;
  } */

  .print-root.credit-history .md-3 {
    flex-basis: 25% !important;
    max-width: 25% !important;
  }

  .print-root.credit-history .md-9 {
    flex-basis: 75% !important;
    max-width: 75% !important;
  }

  .print-root.credit-history .bb-none {
    border-bottom: none !important;
  }

  .print-root.credit-history .br-none {
    border-right: none !important;
  }

  .print-root.credit-history .br {
    border-right: 1px solid #cdcdcd;
  }
  .print-root.credit-history .bordered-grid {
    border-top: 1px solid #cdcdcd;
  }
  .print-root.credit-history .tooltip-value {
    display: none !important;
  }
  .print-root.credit-history .print-value {
    display: block !important;
  }
}

@media screen {
  .print-header {
    display: none !important;
  }

  .print-receipt-footer {
    display: none !important;
  }

  .print-receipt-header {
    display: none !important;
  }

  .d-screen-none {
    display: none !important;
  }
}
