.loading-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 20px;
}

.loader-liquid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #e9e9e9;
  border-radius: 50%;
  position: relative;
  box-shadow:
    0 0 30px 4px rgba(0, 0, 0, 0.5) inset,
    0 5px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.loader-liquid:before,
.loader-liquid:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 45%;
  top: -40%;
}

.loader-liquid:before {
  border-radius: 30%;
  background: rgba(255, 255, 255, 0.4);
  animation: crlMugLoader 2s linear infinite alternate;
}
@keyframes crlMugLoader {
  0%,
  10% {
    transform: translateY(64px);
  }
  90%,
  100% {
    transform: translateY(0px);
  }
}
